.video-repeater-wrapper {
    display: flex;
    max-width: 100%;
    width: 100%;
    padding: 15px;
    position: relative;
    .remove-vr {
        position: absolute;
        top: 11px;
        right: 1px;
        span {
            background-color: red;
            padding: 10px 15px;
            color: #fff;
            cursor: pointer;
        }
    }
    .data {
        width: 100%;
        min-height: 250px;
        display: flex;
        .inputs {
            max-width: 400px;
            width: 100%;
            .input-file-container {
                position: relative;
                .input-file-trigger {
                    text-align: center;
                    display: block;
                    padding: 14px 25px;
                    background: $buttonColor;
                    color: #fff;
                    font-size: 1em;
                    transition: all .4s;
                    cursor: pointer;
                }
                .input-file {
                    position: absolute;
                    top: 0; left: 0;
                    opacity: 0;
                    padding: 14px 0;
                    cursor: pointer;
                    width: 100%;
                }
                .input-file:hover + .input-file-trigger,
                .input-file:focus + .input-file-trigger,
                .input-file-trigger:hover,
                .input-file-trigger:focus {
                    background: $buttonColorHover;
                    color: #fff;
                }
            }
            .row {
                padding-bottom: 15px;
                label {
                    display: block;
                    padding-bottom: 5px;
                }
                input, select, .size-btn {
                    height: 30px;
                    max-width: 400px;
                    width: 100%;
                }
            }
            .remove-row {
                margin: 0;
            }
            .submit {
                width: 100%;
                margin-top: 10px;
                .btn {
                    width: 100%;
                    padding: 14px 25px;
                }
            }
        }
        .preview {
            width: 100%;
            .video {
                display: flex;
                justify-content: center;
                iframe {
                    height: 500px;
                    max-width: 800px;
                    width: 100%;
                    body {
                        background-color: transparent;
                    }
                }
            }
            .sounds {
                display: flex;
                input {
                    padding: 15px;
                    margin: 0 10px;
                }
            }
        }
    }
}