.vr-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    .image-repeater-wrapper  {
        width: 100%;
        .remove-vr {
            display: flex;
            justify-content: flex-end;
            span {
                background-color: red;
                padding: 10px 15px;
                color: #fff;
                cursor: pointer;
            }
        }
        iframe {
            height: 100vh;
        }
    }
}