.quiz {
    .name {
        padding-top: 10px;
        h2 {
            text-align: center;
        }
    }
    .vr-iframe {
        .data {
            .inputs {
                max-width: 90%;
                width: 100%;
                .row {
                    input {
                        max-width: 90%;
                    }
                }
                .submit {
                    max-width: 400px;
                }
            }
        }
    }
}