.select-items {
    .box {
        display: flex;
        padding: 7px 0;
        width: 100% !important;
        .col {
            height: 200px;
            overflow-y: auto;
            width: 50%;
            margin: 10px 0 10px 15px;
            border: 1px solid #d2d2d2;
            &:nth-of-type(1) {
                background-color: #F9F9F9;
                margin: 10px 15px 10px 0;
            }
            .row-item {
                display: flex;
                width: 100%;
                position: relative;
                margin-bottom: 15px;
                cursor: pointer;
                &:hover {
                    .type, span {
                        background-color: $mainColor;
                        color: #fff;
                    }
                }
                &.disabled {
                    cursor: default;
                    color: rgb(199, 193, 193);
                    &:hover {
                        .type, span {
                            cursor: default;
                            background-color: transparent;
                            color: rgb(199, 193, 193);
                        }
                    }
                }
                .image {
                    position: absolute;
                    top: 10px;
                    left: 5px;
                    max-width: 50px;
                    height: 30px;
                }
                .type {
                    position: absolute;
                    line-height: 50px;
                    left: 5px;
                }
                span {
                    display: flex;
                    align-items: center;
                    padding-left: 60px;
                    height: 50px;
                    width: 100%;
                    cursor: pointer;
                }
            }
        }
    }
}