.library {
    .upload-place {
        display: flex;
        flex-direction: column;
        align-items: center;
        .actions {
            display: flex;
            justify-content: center;
            label {
                display: inline-block;
                background-color: #5A503C;
                color: white;
                font-family: 'Roboto', sans-serif;
                cursor: pointer;
                transition: 0.3s;
                padding: 15px 30px;
                font-size: 1rem;
                font-weight: 400;
                border-radius: 3px;
                &:hover {
                    background-color: #7C7855;
                }
            }
            .btn {
                margin-left: 30px;
                padding: 15px 30px;
            }
            .imageName {
                margin-left: 30px;
                padding: 15px 30px;
            }
        }
        .information {
            display: flex;
            justify-content: center;
            padding-top: 15px;
        }
        .box {
            font-size: 1.25rem;
            background-color: #c8dadf;
            position: relative;
            padding: 100px 20px;
            outline: 2px dashed #92b0b3;
            outline-offset: -10px;
            transition: outline-offset .15s ease-in-out, background-color .15s linear;
            max-width: 500px;
            width: 100%;
            margin-bottom: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            #file {
                width: 0.1px;
                height: 0.1px;
                opacity: 0;
                overflow: hidden;
                position: absolute;
                z-index: -1;
            }
            label {
                max-width: 80%;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
                display: inline-block;
                overflow: hidden;
            }
            img {
                display: block;
                padding-bottom: 30px;
            }
        }
    }
    .table {
        display: flex;
        // align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 2px solid #a0a0a0;
        padding: 10px;
        .search-bar {
            margin-right: 10px;
            display: flex;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
            .searchBox {
                background: #a0a0a0;
                height: 60px;
                border-radius: 40px;
                padding: 10px;
                outline: none;
            }
            
            // .searchBox:hover > .searchInput {
            //     width: 240px;
            //     padding: 0 6px;
            // }
            
            .searchBox:hover > .searchButton {
              background: white;
              color : #2f3640;
            }
            
            .searchButton {
                color: white;
                float: right;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: #a0a0a0;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.4s;
                outline: none;
                cursor: pointer;
            }
            
            .searchInput {
                border:none;
                background: none;
                outline:none;
                float:left;
                // padding: 0;
                color: white;
                font-size: 16px;
                transition: 0.4s;
                line-height: 40px;
                // width: 0px;
                width: 240px;
                padding: 0 6px;
            }
            
            @media screen and (max-width: 620px) {
            .searchBox:hover > .searchInput {
                width: 150px;
                padding: 0 6px;
            }
            }
        }
        .images-wrapper {
            display: flex;
            flex-wrap: wrap;
            .image-box {
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: calc(20% - 10px);
                max-height: 150px;
                height: 150px;
                width: 100%;
                cursor: pointer;
                border: 2px solid #a0a0a0;
                padding: 5px;
                margin: 5px;
                img {
                    max-height: 130px;
                    max-width: 100%;
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}