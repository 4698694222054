.library-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(36, 35, 35, 0.7);
    padding: 20px;
    max-width: 100vw;
    width: 100%;
    height: 100vh;
    z-index: 10;
    &.hidden {
        opacity: 0;
    }
    .image-box-popup {
        max-width: 640px;
        padding: 15px 0;
        img {
            border: 3px solid #000;
            max-width: 100%;
            height: 100%;
            width: 100%;
            max-height: calc(100vh - 20px);
        }
    }
    .information-img {
        padding: 30px;
        background-color: #f0f0f0;
        margin-left: 5px;
        position: relative;
        border: 3px solid #000;
        .exit {
            width: 15px;
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            user-select: none;
        }
        .info {
            padding: 15px 0;
            .label, .value {
                color: #000;
            }
            .value {
                font-weight: bold;
            }
        }
    }
}