.pagination-wrapper {
    padding-top: 20px;
    .pagination {
        ul {
            display: flex;
            justify-content: center;
            li {
                border: 2px solid #a0a0a0;
                margin: 0 5px;
                cursor: pointer;
                &.disabled {
                    cursor: no-drop;
                }
                &.next, &.previous {
                    padding: 10px;
                    a {
                    background-color: transparent;
                    border-radius: 100%;
                    width: 20px;
                    height: 20px;
                    position: relative;
                    display: block;
                    z-index: 0;
                    outline: none;
                    &:after {
                        content: "";
                        display: inline-block;
                        background-image: url('./../../../img/arrow-ico.svg');
                        background-repeat: no-repeat;
                        left: calc(50% - 2px);
                        top: calc(50% - 7px);
                        margin-right: 0;
                        height: 11px;
                        width: 7px;
                        z-index: 1;
                        position: relative;
                        transition: .3s ease-in-out transform;
                      }
                    }
                }
                &.next {
                    margin-left: 30px;
                    &:hover {
                        a {
                            &:after {
                                transform: translateX(3px)
                            }
                        }
                    }
                }
                &.previous {
                    margin-right: 40px;
                    a {
                        &:after {
                            transform: rotate(180deg);
                            left: calc(50% - 4px);
                        }
                    }
                    &:hover {
                        a {
                            &:after {
                                transform: translateX(-3px) rotate(180deg);
                            }
                        }
                    }
                }
                a {
                    display: block;
                    outline: none;
                    padding: 10px;
                }
                &.selected {
                    a {
                        font-weight: bold;
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}