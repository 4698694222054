.library-vr {
    .actions {
        display: flex;
        justify-content: center;
        label {
            display: inline-block;
            background-color: #5A503C;
            color: white;
            font-family: 'Roboto', sans-serif;
            cursor: pointer;
            transition: 0.3s;
            padding: 15px 30px;
            font-size: 1rem;
            font-weight: 400;
            border-radius: 3px;
            &:hover {
                background-color: #7C7855;
            }
        }
        .btn {
            margin-left: 30px;
            padding: 15px 30px;
        }
        .imageName {
            margin-left: 30px;
            padding: 15px 30px;
        }
    }
    .vr-iframe {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        .vr-box {
            border: 1px solid #a0a0a0;
            padding: 0;
            margin-bottom: 15px;
            .vr-name {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 100%;
                border: 3px solid #a0a0a0;
                background-color: #cec9c9;
                cursor: pointer;
                span {
                    font-weight: bold;
                    font-size: 18px;
                    text-transform: uppercase;
                    user-select: none;
                }
            }
        }
    }
}